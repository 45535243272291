import { useEffect } from 'react'
import { Photo } from '../Photo';

const Gallery = () => {
  return(
    <div className='gallery-holder'>
      <div className='inline-description'>
        <p className='italic'>client ➝ Experience One | Mercedes-Benz AG<br />
          technology ➝ Angular<br />
          location ➝ Berlin (Germany)</p>
        <p>EO Touch transforms the automotive showroom experience by combining intuitive usability with immersive technology, featuring a large-scale power wall and interactive 3D vehicle configurator.</p>
        <p>I have enhanced system performance through strategic refactoring while developing key features, including a custom animation system and optimized communication between terminals and the large-scale display wall.</p><br />
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/commercial/digital-showroom/eo-digital-showroom-stage.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/commercial/digital-showroom/project_story_1_showroom.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/commercial/digital-showroom/project_story_2_showroom.jpg" alt="" /></div>
      </div>
    </div>
  )
}

export const DIGITALSHOWROOM = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="project-detail-page">
      <h1>• Digital Showroom •</h1>
      <Gallery />
    </div>
  )
}