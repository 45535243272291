import { NavLink, useParams } from 'react-router-dom';
import { useEffect } from 'react'

const Gallery = () => {
  return(
    <div className='gallery-holder'>
      {/* <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/zw/1_00290005.jpg" alt="" /></div>
      </div> */}
      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/zw/2_00290009.jpg" alt="" /></div>
      </div>
      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/zw/3_00290010.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><img src="../../assets/photography/zw/6_00300027.jpg" alt="" /></div>
        <div className='img-holder'><img src="../../assets/photography/zw/5_00300034.jpg" alt="" /></div>
      </div>

      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/zw/9_00290019.jpg" alt="" /></div>
      </div>

      <div className='pair-gallery'>
        <div className='img-holder'><img src="../../assets/photography/zw/8_00290014.jpg" alt="" /></div>
        <div className='img-holder'><img src="../../assets/photography/zw/7_00310030.jpg" alt="" /></div>
      </div>
    </div>
  )
}
const Description = () => {
  return(
    <div className="article-holder">
      <p>Walking around Zagreb West.</p>
    </div>
  )
}

export const ZW = () => {
  const { id } = useParams();
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="project-detail-page">
      <h1>• ZW •</h1>
      <nav>
        <ul>
          <li><NavLink to="/photography/zw/gallery">Gallery</NavLink><span>,&nbsp;</span></li>
          <li><NavLink to="/photography/zw/description">Description</NavLink></li>
        </ul>
      </nav>
      {(id === 'gallery' ? <Gallery /> : <Description />)}
    </div>
  )
}