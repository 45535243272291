import { NavLink, useParams } from 'react-router-dom';
import { useEffect } from 'react'

const Gallery = () => {
  return(
    <div className='gallery-holder'>
      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/d/001_03140019.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><img src="../../assets/photography/d/003_Photo12_25A.jpg" alt="" /></div>
        <div className='img-holder'><img src="../../assets/photography/d/002_Photo04_33A.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><img src="../../assets/photography/d/004_91280005.jpg" alt="" /></div>
        <div className='img-holder'><img src="../../assets/photography/d/005_91280001_2.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><img src="../../assets/photography/d/007_91250005.jpg" alt="" /></div>
        <div className='img-holder'><img src="../../assets/photography/d/008_91250008.jpg" alt="" /></div>
      </div>
      {/* <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/d/009_91260013.jpg" alt="" /></div>
      </div> */}
      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/d/010_03130009.jpg" alt="" /></div>
      </div>
      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/d/011_91250001.jpg" alt="" /></div>
      </div>
      {/* <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/d/012_03130015.jpg" alt="" /></div>
      </div> */}
      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/d/013_91280009.jpg" alt="" /></div>
      </div>
      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/d/014_91280013.jpg" alt="" /></div>
      </div>
      {/* <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/d/016_91280014.jpg" alt="" /></div>
      </div> */}
    </div>
  )
}
const Description = () => {
  return(
    <div className="article-holder">
      <p>Walking around Split and Makarska rivijera.</p>
    </div>
  )
}

export const D = () => {
  const { id } = useParams();
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="project-detail-page">
      <h1>• D •</h1>
      <nav>
        <ul>
          <li><NavLink to="/photography/d/gallery">Gallery</NavLink><span>,&nbsp;</span></li>
          <li><NavLink to="/photography/d/description">Description</NavLink></li>
        </ul>
      </nav>
      {(id === 'gallery' ? <Gallery /> : <Description />)}
    </div>
  )
}