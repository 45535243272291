import { NavLink, useParams } from 'react-router-dom';
import { useEffect } from 'react'

const Gallery = () => {
  return(
    <div className='gallery-holder'>
      <div className='pair-gallery'>
        <div className='img-holder'><img src="../../assets/photography/wp/c_05440010.jpg" alt="" /></div>
        <div className='img-holder'><img src="../../assets/photography/wp/b_05440009.jpg" alt="" /></div>
        <div className='img-holder'><img src="../../assets/photography/wp/a_05440006.jpg" alt="" /></div>
      </div>
    </div>
  )
}
const Description = () => {
  return(
    <div className="article-holder">
      <p>Series from a walk in North West Croatia.</p>
    </div>
  )
}

export const WP = () => {
  const { id } = useParams();
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="project-detail-page">
      <h1>• WP •</h1>
      <nav>
        <ul>
          <li><NavLink to="/photography/wp/gallery">Gallery</NavLink><span>,&nbsp;</span></li>
          <li><NavLink to="/photography/wp/description">Description</NavLink></li>
        </ul>
      </nav>
      {(id === 'gallery' ? <Gallery /> : <Description />)}
    </div>
  )
}