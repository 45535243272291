import { NavLink, useParams } from 'react-router-dom';
import { useEffect } from 'react'
import { Photo } from '../Photo';

const Gallery = () => {
  return(
    <div className='gallery-holder'>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/endless/1_bw.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/endless/2_bw.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/endless/3_bw.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/endless/4_bw.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/endless/5_bw.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/endless/6_bw.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/endless/7_bw.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/endless/8_bw.jpg" alt="" /></div>
      </div>
      <div className='inline-description'>
        <p>Process</p>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/endless/1.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/endless/2.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/endless/3.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/endless/4.jpg" alt="" /></div>
      </div>
    </div>
  )
}

const Description = () => {
  return(
    <div className="article-holder">
      <p>Endless is a projection-based imaginary space conjured out of reflections between glassless mirrors and projection surfaces, connected at a 90° angle. The illusion of depth and crossing animated planes challenges spatial perception, constantly changing perspective with the viewer's position in space and the projected content. The animation follows the idea of a setup and expands the optical illusion with its specific geometric forms.</p>
      <p>The setup is arranged in a "zig-zag" shape where specific surfaces are set for frontal video projection or LED panels next to neighboring surfaces made of glassless mirrors - both fixed to a lightweight aluminum structure. The entire installation can be easily assembled and meets the standards of economical flat-pack transport.</p>
      <p><i>Software</i><br />
        vvvv
      </p>
      <p><i>co-author</i><br />
        <a href="http://www.smpdo.eu/" target="_blank">Simon Morasi Pipercic</a>
      </p>
      <p><i>Press</i><br />
        <a href="https://www.domusweb.it/en/news/2017/02/22/endless_simon_morasi_pipercic_vedran_kolac.html" target="_blank">DOMUS</a><br />
      </p>
    </div>
    
  )
}

export const ENDLESS = () => {
  const { id } = useParams();
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="project-detail-page">
      <h1>• ENDLESS •</h1>
      <nav>
        <ul>
          <li><NavLink to="/private/endless/gallery">Gallery</NavLink><span>,&nbsp;</span></li>
          <li><NavLink to="/private/endless/description">Description</NavLink></li>
        </ul>
      </nav>
      {(id === 'gallery' ? <Gallery /> : <Description />)}
    </div>
  )
}