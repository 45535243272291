import { useState } from 'react';

export const Photo = props => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  return (
    <img
      src={ props.src }
      alt=""
      className={`smooth-image image-${
        isImageLoaded ? 'visible' :  'hidden'
      }`}
      onLoad={()=> setIsImageLoaded(true)}
    />
  )
}