import { NavLink, useParams } from 'react-router-dom';
import { useEffect } from 'react'
import { Photo } from '../Photo';

const Gallery = () => {
  return(
    <div className='gallery-holder'>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/moe-2/1.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/moe-2/2.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/moe-2/8127387463_75060ba4fc_o_w.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/moe-2/8127425489_71e17a8755_o_w.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/moe-2/8127419265_2a913814a9_o_w.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/moe-2/8127418739_997033e165_o_w.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/moe-2/8127421725_ef73382e8e_o_w.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/moe-2/8133176320_5e64e1d0cd_o_w.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/moe-2/8133120271_cd8d78f1db_o_w.jpg" alt="" /></div>
      </div>
      <div className='inline-description'>
        <p>Process</p>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/moe-2/a.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/moe-2/b.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/moe-2/c.jpg" alt="" /></div>
      </div>
    </div>
  )
}

const Description = () => {
  return(
    <div className="article-holder">
      <p>MOE II is a light and video-augmented construction based on a three-dimensional plane distortion defined by multiplying two triangles. The elements are CNC cut out of polyethylene (PELD) sheets with only 5% of material waste and thermoformed in a mold. The elements are assembled into a plane and distorted into an irregular three-dimensional form that divides the space into several levels.</p>
      <p>Video is mapped, generated and animated with our custom made software.</p>
      <p><i>Software</i><br />
        vvvv & Blender
      </p>
      <p><i>Image credits</i><br />lupi spuma</p>
      <p><i>Co-author</i><br />
        <a href="http://www.smpdo.eu/" target="_blank">Simon Morasi Pipercic</a>
      </p>
      <p><i>Press</i><br />
        <a href="http://www.framemag.com/video/2060/Project-MOE-by-Simon-Morasi-Pipercic-and-Vedran-Kolac.html" target="_blank">FRAME magazine</a><br />
      </p>
    </div>
    
  )
}

export const MOEII = () => {
  const { id } = useParams();
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="project-detail-page">
      <h1>• MOE II •</h1>
      <nav>
        <ul>
          <li><NavLink to="/private/moe-2/gallery">Gallery</NavLink><span>,&nbsp;</span></li>
          <li><NavLink to="/private/moe-2/description">Description</NavLink></li>
        </ul>
      </nav>
      {(id === 'gallery' ? <Gallery /> : <Description />)}
    </div>
  )
}