import { Link } from 'react-router-dom';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import { Photo } from './Photo';

export const Projects = () => {
  const fImgs = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  const fImg = Math.round(Math.random()* (fImgs.length - 1));

  const cImgs = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  const cImg = Math.round(Math.random()* (cImgs.length - 1));

  const aImgs = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  const aImg = Math.round(Math.random()* (aImgs.length - 1));

  const mImgs = [121, 114, 105, 88, 57, 48, 19, 8, 42]
  const mImg = Math.round(Math.random()* (mImgs.length - 1));
  return (
    <div className="project-list-holder">
      <ResponsiveMasonry columnsCountBreakPoints={{0: 2, 640: 2, 1024: 3, 1440: 3, 1920: 4, 2560: 4, 3200: 5}}>
        <Masonry gutter='20px'>
          <div className="thumb" >
            <Link to="/private/floating-cities/gallery">
              <div className='img-container'>
              <Photo src={'../assets/private/floating-cities/1thumb.jpg'} />
              </div>
              <h1>• FLOATING CITIES •</h1>
            </Link>
          </div>
          <div className="thumb" >
            <Link to="/private/crash/gallery">
              <div className='img-container'>
              <Photo src={'../assets/private/crash/10thumb.jpg'} />
              </div>
              <h1>• CRASH •</h1>
            </Link>
          </div>
          <div className="thumb" >
            <Link to="/private/authonomy/gallery">
              <div className='img-container'>
              <Photo src={'../assets/private/authonomy/2thumb.jpg'} />
              </div>
              <h1>• AUTONOMY •</h1>
            </Link>
          </div>
          <div className="thumb" >
            <Link to="/private/monsters/gallery">
              <div className='img-container'>
                <Photo src={`../assets/private/monsters/${mImgs[mImg]}.jpg`} />
              </div>
              <h1>• MNSTR •</h1>
            </Link>
          </div>
          <div className="thumb" >
            <Link to="/private/c-all/gallery">
              <div className='img-container'>
                <Photo src={'../assets/private/c-all/1.jpg'} />
              </div>
              <h1>• C-ALL •</h1>
            </Link>
          </div>
          <div className="thumb" >
            <Link to="/private/artgerecht/gallery">
              <div className='img-container'>
                <Photo src="../assets/private/artgerecht/thumb_3.jpg" />
              </div>
              <h1>• ARTGERECHT •</h1>
            </Link>
          </div>
          {/* <div className="thumb" >
            <Link to="/private/endless/gallery">
              <div className='img-container'>
                <Photo src="../assets/private/endless/06040004_2_1200px.jpg"/>
              </div>
              <h1>• ENDLESS •</h1>
            </Link>
          </div> */}
          <div className="thumb" >
            <Link to="/private/schallfeld-ens/gallery">
              <div className='img-container'>
                <Photo src={'../assets/private/schallfeld-ens/thumb.jpg'} />
              </div>
              <h1>• TodaysArt.SE •</h1>
            </Link>
          </div>
          <div className="thumb" >
            <Link to="/private/moe-2/gallery">
              <div className='img-container'>
                <Photo src="../assets/private/moe-2/thumb_8.jpg" />
              </div>
              <h1>• MOE II •</h1>
            </Link>
          </div>
          <div className="thumb" >
            <Link to="/private/moe-1/gallery">
              <div className='img-container'>
                <Photo src="../assets/private/moe-1/thumb-5.jpg" />
              </div>
              <h1>• MOE I •</h1>
            </Link>
          </div>
          {/* <div className="thumb" >
            <Link to="/private/space-is-only-noise/gallery">
              <div className='img-container'>
                <Photo src="../assets/private/space-is-only-noise/thumb_2.jpg" />
              </div>
              <h1>• S/N •</h1>
            </Link>
          </div> */}
          {/* <div className="thumb" >
            <Link to="/private/disturbing-the-void/gallery">
              <div className='img-container'>
                <Photo src="../assets/private/disturbing-the-void/thumb_2.jpg" />
              </div>
              <h1>• D.T.VOID •</h1>
            </Link>
          </div> */}
        </Masonry>
      </ResponsiveMasonry>
    </div>
  )
}