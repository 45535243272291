import { useEffect } from 'react'
import { Photo } from '../Photo';

const Gallery = () => {
  return(
    <div className='gallery-holder'>
      <div className='inline-description'>
        <p className='italic'>client ➝ Experience One | Daimler AG<br />
          technology ➝ React, AEM<br />
          location ➝ Berlin (Germany)
          </p>
        <p>OneWeb Vans is a premium B2B platform deployed across 25 markets, featuring advanced van configuration tools for customized fleet solutions.</p>
        <p>I led the implementation of a new frontend architecture following Mercedes AG's updated UI guidelines, while coordinating internal and external design teams to streamline workflows between React/Web Components frontend and backend development.</p><br />
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/commercial/one-web-vans/project_quote_container_3_OneWebVans.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/commercial/one-web-vans/OneWebVans-a.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/commercial/one-web-vans/OneWebVans-b.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/commercial/one-web-vans/OneWebVans-c.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/commercial/one-web-vans/OneWebVans-d.jpg" alt="" /></div>
      </div>
    </div>
  )
}

export const MBVANS = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="project-detail-page">
      <h1>• OneWeb Vans •</h1>
      <Gallery />
    </div>
  )
}