import { NavLink, useParams } from 'react-router-dom';
import { useEffect } from 'react'
import { Photo } from '../Photo';

const Gallery = () => {
  return(
    <div className='gallery-holder'>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/artgerecht/nzym_illustration.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/artgerecht/omega_illustration.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/artgerecht/nergy_illustration.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/artgerecht/lferin_illustration.jpg" alt="" /></div>
      </div>
      {/* <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/artgerecht/Artgerecht_0110499.jpg" alt="" /></div>
      </div> */}
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/artgerecht/manuka_illustration.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/artgerecht/eneregy.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/artgerecht/pack_2.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/artgerecht/pack_3.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/artgerecht/pack_4.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/artgerecht/pack_1.jpg" alt="" /></div>
      </div>
      <div className='inline-description'>
        <p>Process</p>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/artgerecht/sketch_1.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/artgerecht/sketch_2.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/artgerecht/sketch_3.jpg" alt="" /></div>
      </div>
      {/* <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/artgerecht/test_2.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/artgerecht/test_4.jpg" alt="" /></div>
      </div> */}
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/artgerecht/examples.jpg" alt="" /></div>
      </div>
    </div>
  )
}

const Description = () => {
  return(
    <div className="article-holder">
      <p>Artgerecht is premium dietary supplements and lifestyle company creating products tailored to human DNA.</p>
      <p>We worked closely with <a href="https://hirschl.studio/">hirschl.studio</a> on identity and packaging design. All graphical content was written in code using procedural and generative design methods.</p>
      <p><i>Software</i><br />
        Javascript, Processing & vvvv
      </p>
    </div>
    
  )
}

export const ARTGERECHT = () => {
  const { id } = useParams();
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="project-detail-page">
      <h1>• ARTGERECHT •</h1>
      <nav>
        <ul>
          <li><NavLink to="/private/artgerecht/gallery">Gallery</NavLink><span>,&nbsp;</span></li>
          <li><NavLink to="/private/artgerecht/description">Description</NavLink></li>
        </ul>
      </nav>
      {(id === 'gallery' ? <Gallery /> : <Description />)}
    </div>
  )
}