import { useEffect } from 'react'
import { Photo } from '../Photo';

const Gallery = () => {
  return(
    <div className='gallery-holder'>
      <div className='inline-description'>
        <p className='italic'>client ➝ Experience One | Mercedes-Benz AG<br />
          technology ➝ Angular<br />
          location ➝ Berlin (Germany)</p>
        <p>ADVANTAGES is Mercedes' global sales assistant platform, serving 70 markets with comprehensive product information, interactive configurators, and market-specific data.</p>
        <p>I have led project in close collaboration with design team, managed remote AEM backend developers, and worked with senior management during contract renewals. Key responsibilities included feature planning, implementation estimation, and functionality enhancement.</p><br />
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/commercial/advantages/project_stageadvantages.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/commercial/advantages/advantages-1.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/commercial/advantages/advantages-2.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/commercial/advantages/advantages-3.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/commercial/advantages/advantages-4.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/commercial/advantages/advantages-5.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/commercial/advantages/advantages-6.jpg" alt="" /></div>
      </div>
    </div>
  )
}

export const ADVANTAGES = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="project-detail-page">
      <h1>• ADVANTAGES •</h1>
      <Gallery />
    </div>
  )
}