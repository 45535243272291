import { NavLink, useParams } from 'react-router-dom';
import { useEffect } from 'react'
import { Photo } from '../Photo';

const Gallery = () => {
  return(
    <div className='gallery-holder'>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/moe-1/process-1_bw.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/moe-1/5108585059_ce9dfbdb18_o_w.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/moe-1/5106059223_4ef0ee0a95_o_w.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/moe-1/5106656540_e8da11813d_o_w.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/moe-1/5106067023_5e27d0e9e5_o_w.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/moe-1/5.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/moe-1/19.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/moe-1/awsome-tapes.jpg" alt="" /></div>
      </div>
    </div>
  )
}

const Description = () => {
  return(
    <div className="article-holder">
      <p>MOE I is a three-dimensional screen for music events consisting of standard polystyrene (EPS) panels with slots carved with a scalpel for connecting to each other.</p>
      <p>Video is mapped, generated and animated with our custom made software.</p>
      <p><i>Software</i><br />
        vvvv & Blender
      </p>
      <p><i>Image credits</i><br />lupi spuma</p>
      <p><i>Co-author</i><br />
        <a href="http://www.smpdo.eu/" target="_blank">Simon Morasi Pipercic</a>
      </p>
      <p><i>Press</i><br />
        <a href="https://www.designboom.com/architecture/moe-clb-pop-up-event-cube-by-project-moe/" target="_blank">Designboom</a><br />
        <a href="https://www.psfk.com/2010/03/modular-screen-syncs-audio-and-video-onto-configurable-components.html" target="_blank">PSFK</a><br />
      </p>
    </div>
    
  )
}

export const MOEI = () => {
  const { id } = useParams();
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="project-detail-page">
      <h1>• MOE I •</h1>
      <nav>
        <ul>
          <li><NavLink to="/private/moe-1/gallery">Gallery</NavLink><span>,&nbsp;</span></li>
          <li><NavLink to="/private/moe-1/description">Description</NavLink></li>
        </ul>
      </nav>
      {(id === 'gallery' ? <Gallery /> : <Description />)}
    </div>
  )
}