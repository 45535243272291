import { NavLink, useParams } from 'react-router-dom';
import { useEffect } from 'react'
import { Photo } from '../Photo';

const Gallery = () => {
  return(
    <div className='gallery-holder'>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/monsters/121.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/114.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/monsters/105.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/88.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/57.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/monsters/48.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/19.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/8.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/42.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/monsters/2.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/3.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/4.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/7.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/11.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/monsters/13.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/14.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/15.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/16.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/17.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/22.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/monsters/24.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/28.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/34.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/40.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/44.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/47.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/49.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/monsters/51.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/56.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/58.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/64.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/69.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/70.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/72.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/73.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/monsters/74.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/75.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/79.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/81.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/86.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/92.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/93.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/94.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/95.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/monsters/97.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/99.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/103.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/104.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/43.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/44.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/45.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/47.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/48.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/monsters/49.jpg" alt="" /></div>
      </div>
    </div>
  )
}

const Description = () => {
  return(
    <div className="article-holder">
      <p>Derivation of a Voronoi algorithm used as a generator for infinite series of face || bugs-like structures.</p>
      <p><i>Software</i><br />
        Javascript
      </p>
    </div>
    
  )
}

export const MNSTR = () => {
  const { id } = useParams();
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="project-detail-page">
      <h1>• MNSTR •</h1>
      <nav>
        <ul>
          <li><NavLink to="/private/monsters/gallery">Gallery</NavLink><span>,&nbsp;</span></li>
          <li><NavLink to="/private/monsters/description">Description</NavLink></li>
        </ul>
      </nav>
      {(id === 'gallery' ? <Gallery /> : <Description />)}
    </div>
  )
}