import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

export const Time = () => {
  return (
    <div className="project-list-holder">
      <ResponsiveMasonry columnsCountBreakPoints={{0: 1, 640: 2, 1024: 3, 1920: 4, 2560: 5, 3200: 6 }}>
        <Masonry gutter='20px'>
          <img className="thumb" src="../assets/projects/vr-exp/1.jpg" alt="" />
          {/* <img className="thumb" src="../assets/projects/vr-exp/2.jpg" alt="" /> */}
          {/* <img className="thumb" src="../assets/projects/vr-exp/3.jpg" alt="" /> */}
          <img className="thumb" src="../assets/projects/vr-exp/4.jpg" alt="" />

          <img className="thumb" src="../assets/projects/c-all/ab-1.jpg" alt="" />
          <img className="thumb" src="../assets/projects/c-all/aw-1.jpg" alt="" />
          {/* <img className="thumb" src="../assets/projects/c-all/bb-1.jpg" alt="" /> */}
          {/* <img className="thumb" src="../assets/projects/c-all/bw-1.jpg" alt="" /> */}
          {/* <img className="thumb" src="../assets/projects/c-all/cb-1.jpg" alt="" /> */}
          <img className="thumb" src="../assets/projects/c-all/cw-1.jpg" alt="" />

          <img className="thumb" src="../assets/projects/endless/1_bw.jpg" alt="" />
          <img className="thumb" src="../assets/projects/endless/2_bw.jpg" alt="" />
          <img className="thumb" src="../assets/projects/endless/3_bw.jpg" alt="" />
          <img className="thumb" src="../assets/projects/endless/4_bw.jpg" alt="" />
          <img className="thumb" src="../assets/projects/endless/5_bw.jpg" alt="" />
          <img className="thumb" src="../assets/projects/endless/6_bw.jpg" alt="" />
          <img className="thumb" src="../assets/projects/endless/7_bw.jpg" alt="" />
          <img className="thumb" src="../assets/projects/endless/8_bw.jpg" alt="" />

          <img className="thumb" src="../assets/projects/endless/1.jpg" alt="" />
          <img className="thumb" src="../assets/projects/endless/2.jpg" alt="" />
          <img className="thumb" src="../assets/projects/endless/3.jpg" alt="" />
          <img className="thumb" src="../assets/projects/endless/4.jpg" alt="" />

          <img className="thumb" src="../assets/projects/moe-2/1.jpg" alt="" />
          <img className="thumb" src="../assets/projects/moe-2/2.jpg" alt="" />

          <img className="thumb" src="../assets/projects/moe-2/8127387463_75060ba4fc_o_w.jpg" alt="" />
          <img className="thumb" src="../assets/projects/moe-2/8127425489_71e17a8755_o_w.jpg" alt="" />
          <img className="thumb" src="../assets/projects/moe-2/8127419265_2a913814a9_o_w.jpg" alt="" />
          <img className="thumb" src="../assets/projects/moe-2/8133150029_022c182184_o_w.jpg" alt="" />
          <img className="thumb" src="../assets/projects/moe-2/8127418739_997033e165_o_w.jpg" alt="" />
          <img className="thumb" src="../assets/projects/moe-2/8127421725_ef73382e8e_o_w.jpg" alt="" />
          <img className="thumb" src="../assets/projects/moe-2/8133145238_8e6db26879_o_w.jpg" alt="" />
          <img className="thumb" src="../assets/projects/moe-2/8133176320_5e64e1d0cd_o_w.jpg" alt="" />
          <img className="thumb" src="../assets/projects/moe-2/8133120271_cd8d78f1db_o.jpg" alt="" />

          <img className="thumb" src="../assets/projects/moe-2/a.jpg" alt="" />
          <img className="thumb" src="../assets/projects/moe-2/b.jpg" alt="" />
          <img className="thumb" src="../assets/projects/moe-2/c.jpg" alt="" />

          <img className="thumb" src="../assets/projects/moe-1/process-1_bw.jpg" alt="" />
          <img className="thumb" src="../assets/projects/moe-1/5108585059_ce9dfbdb18_o_w.jpg" alt="" />
          <img className="thumb" src="../assets/projects/moe-1/5106059223_4ef0ee0a95_o_w.jpg" alt="" />
          <img className="thumb" src="../assets/projects/moe-1/5106656540_e8da11813d_o_w.jpg" alt="" />
          <img className="thumb" src="../assets/projects/moe-1/5106067023_5e27d0e9e5_o_w.jpg" alt="" />

          <img className="thumb" src="../assets/projects/moe-1/5.jpg" alt="" />
          <img className="thumb" src="../assets/projects/moe-1/19.jpg" alt="" />
          <img className="thumb" src="../assets/projects/moe-1/awsome-tapes.jpg" alt="" />


          {/* <img className="thumb" src="https://dummyimage.com/640x480/000/fff&text=2" alt="" />
          <img className="thumb" src="https://dummyimage.com/1200x1200/000/fff&text=3" alt="" />
          <img className="thumb" src="https://dummyimage.com/800x400/000/fff&text=4" alt="" />
          <img className="thumb" src="https://dummyimage.com/640x480/000/fff&text=5" alt="" />
          <img className="thumb" src="https://dummyimage.com/800x400/000/fff&text=6" alt="" />
          <img className="thumb" src="https://dummyimage.com/800x400/000/fff&text=7" alt="" />
          <img className="thumb" src="https://dummyimage.com/600x400/000/fff&text=8" alt="" />
          <img className="thumb" src="https://dummyimage.com/640x480/000/fff&text=9" alt="" />
          <img className="thumb" src="https://dummyimage.com/1200x1200/000/fff&text=10" alt="" />
          <img className="thumb" src="https://dummyimage.com/800x400/000/fff&text=11" alt="" />
          <img className="thumb" src="https://dummyimage.com/640x480/000/fff&text=12" alt="" />
          <img className="thumb" src="https://dummyimage.com/800x400/000/fff&text=13" alt="" />
          <img className="thumb" src="https://dummyimage.com/640x480/000/fff&text=14" alt="" />
          <img className="thumb" src="https://dummyimage.com/800x400/000/fff&text=15" alt="" />
          <img className="thumb" src="https://dummyimage.com/800x400/000/fff&text=16" alt="" />
          <img className="thumb" src="https://dummyimage.com/600x400/000/fff&text=17" alt="" />
          <img className="thumb" src="https://dummyimage.com/640x480/000/fff&text=18" alt="" />
          <img className="thumb" src="https://dummyimage.com/1200x1200/000/fff&text=19" alt="" />
          <img className="thumb" src="https://dummyimage.com/800x400/000/fff&text=20" alt="" />
          <img className="thumb" src="https://dummyimage.com/640x480/000/fff&text=21" alt="" />
          <img className="thumb" src="https://dummyimage.com/800x400/000/fff&text=22" alt="" /> */}
        </Masonry>
      </ResponsiveMasonry>
    </div>
  )
}