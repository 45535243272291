import { NavLink, useParams } from 'react-router-dom';
import { useEffect } from 'react'
import { Photo } from '../Photo';

const Gallery = () => {
  return(
    <div className='gallery-holder'>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/floating-cities/1.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/floating-cities/2.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/floating-cities/3.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/floating-cities/4.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/floating-cities/5.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/floating-cities/6.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/floating-cities/7.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/floating-cities/8.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/floating-cities/9.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/floating-cities/10.jpg" alt="" /></div>
      </div>
    </div>
  )
}

const Description = () => {
  return(
    <div className="article-holder">
      <p>A study in composition with recursions. <br />Homage to ideas of Italo Calvino.</p>
      <p>Published as NFT series in collaboration with <a href="https://www.editart.xyz/series/KT1WUVXSJoTu3N9sJMDhLz7T1Zjk1KwuGdhV">EditArt</a><br />
      Also available on <a href="https://vedrankolac.github.io/floating-cities/">GitHub</a></p>
      <p><i>Software</i><br />
        Javascript, threejs and Rapier
      </p>
    </div>
    
  )
}

export const FLOATINGCITIES = () => {
  const { id } = useParams();
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="project-detail-page">
      <h1>• FLOATING CITIES •</h1>
      <nav>
        <ul>
          <li><NavLink to="/private/floating-cities/gallery">Gallery</NavLink><span>,&nbsp;</span></li>
          <li><NavLink to="/private/floating-cities/description">Description</NavLink></li>
        </ul>
      </nav>
      {(id === 'gallery' ? <Gallery /> : <Description />)}
    </div>
  )
}