import { NavLink, useParams } from 'react-router-dom';
import { useEffect } from 'react'
import { Photo } from '../Photo';

const Gallery = () => {
  return(
    <div className='gallery-holder'>
      <div className='pair-gallery'>
        {/* <div className='img-holder'><Photo src="../../assets/private/c-all/aw-1.jpg" alt="" /></div> */}
        {/* <div className='img-holder'>
          <video controls width="100%">
            <source src="../../assets/private/c-all/call.mov" type="video/mp4" />
          </video>
        </div> */}
        <video controls>
            <source src="../../assets/private/c-all/call.mov" type="video/mp4" />
          </video>
        <div className='img-holder'><Photo src="../../assets/private/c-all/ab-1.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/c-all/bb-1.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/c-all/bw-1.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/c-all/cw-1.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/c-all/cb-1.jpg" alt="" /></div>
      </div>
    </div>
  )
}

const Description = () => {
  return(
    <div className="article-holder">
      <p>Connect all algorithm implementation in the VR environment.</p>
      <p><i>Software</i><br />
        Javascript, threejs & tonejs
      </p>
    </div>
    
  )
}

export const CALL = () => {
  const { id } = useParams();
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="project-detail-page">
      <h1>• C-ALL •</h1>
      <nav>
        <ul>
          <li><NavLink to="/private/c-all/gallery">Gallery</NavLink><span>,&nbsp;</span></li>
          <li><NavLink to="/private/c-all/description">Description</NavLink></li>
        </ul>
      </nav>
      {(id === 'gallery' ? <Gallery /> : <Description />)}
    </div>
  )
}