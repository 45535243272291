import { useEffect } from 'react'
import { Photo } from '../Photo';

const Gallery = () => {
  return(
    <div className='gallery-holder'>
      <div className='inline-description'>
        <p className='italic'>client ➝ disko404<br />
          technology ➝ Javascript, Django</p>
        <p>My role involved new Shopify theme development in close collaboration with the design team. I did a complete rewrite of the shop and decreased load time by more than 70%. I have also developed a new system for advanced homepage structure configuration and various sales-specific tools.</p>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/commercial/sphera/pc_01.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/commercial/sphera/pc_02.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/commercial/sphera/pc_03.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/commercial/sphera/pc_04.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/commercial/sphera/pc_05.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/commercial/sphera/pc_06.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/commercial/sphera/pc_07.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/commercial/sphera/pc_08.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/commercial/sphera/pc_09.jpg" alt="" /></div>
      </div>
    </div>
  )
}

export const DISKO404 = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="project-detail-page">
      <h1>• disko404 •</h1>
      <Gallery />
    </div>
  )
}