import { NavLink, useParams } from 'react-router-dom';
import { useEffect } from 'react'
import { Photo } from '../Photo';

const Gallery = () => {
  return(
    <div className='gallery-holder'>
      <div className='pair-gallery'>
      <div className='img-holder'><Photo src="../../assets/private/disturbing-the-void/2e18c363edb37865c40f9a08610a42a5_1440px.jpg" alt="" /></div>
      <div className='img-holder'><Photo src="../../assets/private/disturbing-the-void/4596860ec3bd388482a60765745a19df_1440px.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/disturbing-the-void/94c94a4e9b1c73d28e9f16e0f7fbfc97_1920px.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/disturbing-the-void/ed67daf6ae42fae3e7a1cb94a0af4288_1920px.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/disturbing-the-void/fe47f9d3f5c798e57e2cc5a8cba11e2c_1920px.jpg" alt="" /></div>
      </div>
    </div>
  )
}

const Description = () => {
  return(
    <div className="article-holder">
      <p>Series of generative graphics based on noise filtering.</p>
      <p><i>Software</i><br />
        Javascript
      </p>
    </div>
    
  )
}

export const DTVOID = () => {
  const { id } = useParams();
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="project-detail-page">
      <h1>• D.T.VOID •</h1>
      <nav>
        <ul>
          <li><NavLink to="/private/disturbing-the-void/gallery">Gallery</NavLink><span>,&nbsp;</span></li>
          <li><NavLink to="/private/disturbing-the-void/description">Description</NavLink></li>
        </ul>
      </nav>
      {(id === 'gallery' ? <Gallery /> : <Description />)}
    </div>
  )
}