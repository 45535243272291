import { NavLink, useParams } from 'react-router-dom';
import { useEffect } from 'react'

const Gallery = () => {
  return(
    <div className='gallery-holder'>
      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/ldn/1_02040020.jpg" alt="" /></div>
      </div>
      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/ldn/2_02040014.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><img src="../../assets/photography/ldn/3_02020025.jpg" alt="" /></div>
        <div className='img-holder'><img src="../../assets/photography/ldn/4_02030022.jpg" alt="" /></div>
      </div>
      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/ldn/5_02030023.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><img src="../../assets/photography/ldn/6_02030030.jpg" alt="" /></div>
        <div className='img-holder'><img src="../../assets/photography/ldn/7_02040006.jpg" alt="" /></div>
      </div>
      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/ldn/9_02020026.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><img src="../../assets/photography/ldn/10_02040019.jpg" alt="" /></div>
        <div className='img-holder'><img src="../../assets/photography/ldn/11_02030032.jpg" alt="" /></div>
      </div>
      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/ldn/12_02030007.jpg" alt="" /></div>
      </div>
      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/ldn/15_02030003.jpg" alt="" /></div>
      </div>
    </div>
  )
}
const Description = () => {
  return(
    <div className="article-holder">
      <p>Walk around North East London.</p>
    </div>
  )
}

export const LDN = () => {
  const { id } = useParams();
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="project-detail-page">
      <h1>• LDN •</h1>
      <nav>
        <ul>
          <li><NavLink to="/photography/LDN/gallery">Gallery</NavLink><span>,&nbsp;</span></li>
          <li><NavLink to="/photography/LDN/description">Description</NavLink></li>
        </ul>
      </nav>
      {(id === 'gallery' ? <Gallery /> : <Description />)}
    </div>
  )
}