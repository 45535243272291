import { NavLink, useParams } from 'react-router-dom';
import { useEffect } from 'react'

const Gallery = () => {
  return(
    <div className='gallery-holder'>
      <div className='pair-gallery'>
        <div className='img-holder'><img src="../../assets/photography/s3/Kolac_008.jpg" alt="" /></div>
        <div className='img-holder'><img src="../../assets/photography/s3/Kolac_018.jpg" alt="" /></div>
      </div>

      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/s3/Kolac_012.jpg" alt="" /></div>
        {/* <div className='img-holder'><img src="../../assets/photography/s3/Kolac_010.jpg" alt="" /></div> */}
      </div>

      <div className='pair-gallery'>
        <div className='img-holder'><img src="../../assets/photography/s3/Kolac_001.jpg" alt="" /></div>
        <div className='img-holder'><img src="../../assets/photography/s3/Kolac_011.jpg" alt="" /></div>
      </div>

      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/s3/Kolac_031.jpg" alt="" /></div>
        {/* <div className='img-holder'><img src="../../assets/photography/s3/Kolac_026.jpg" alt="" /></div> */}
      </div>

      <div className='pair-gallery'>
        <div className='img-holder'><img src="../../assets/photography/s3/Kolac_009.jpg" alt="" /></div>
        <div className='img-holder'><img src="../../assets/photography/s3/Kolac_027.jpg" alt="" /></div>
      </div>

      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/s3/Kolac_032.jpg" alt="" /></div>
      </div>

      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/s3/Kolac_023.jpg" alt="" /></div>
      </div>

      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/s3/Kolac_007.jpg" alt="" /></div>
      </div>

      <div className='pair-gallery'>
        <div className='img-holder'><img src="../../assets/photography/s3/Kolac_014.jpg" alt="" /></div>
        <div className='img-holder'><img src="../../assets/photography/s3/Kolac_030.jpg" alt="" /></div>
      </div>

      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/s3/Kolac_025.jpg" alt="" /></div>
      </div>

      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/s3/Kolac_015.jpg" alt="" /></div>
      </div>

      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/s3/Kolac_028.jpg" alt="" /></div>
      </div>

      <div className='pair-gallery'>
        <div className='img-holder'><img src="../../assets/photography/s3/Kolac_003.jpg" alt="" /></div>
        <div className='img-holder'><img src="../../assets/photography/s3/Kolac_005.jpg" alt="" /></div>
      </div>

      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/s3/Kolac_019.jpg" alt="" /></div>
      </div>

      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/s3/Kolac_017.jpg" alt="" /></div>
      </div>

      <div className='pair-gallery'>
        <div className='img-holder'><img src="../../assets/photography/s3/Kolac_020.jpg" alt="" /></div>
        <div className='img-holder'><img src="../../assets/photography/s3/Kolac_021.jpg" alt="" /></div>
      </div>

      {/* <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/s3/Kolac_016.jpg" alt="" /></div>
      </div> */}

      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/s3/Kolac_004.jpg" alt="" /></div>
      </div>
    </div>
  )
}
const Description = () => {
  return(
    <div className="article-holder">
      <p>S3 is a photography series of Split 3 building complex made on east side of Split city in Croatia, from eighties until seventies as a result of urban development plan made in 1969. while Croatia was still a part of Yugoslavia, a communist state that doesn’t exists anymore.</p>
      <p>Main reason for shooting was to discover dramatic and abstract concrete compositions that await visitor on east side entry of the city and which definition escapes to be defined on the border of sculpture and a house.</p>
      <p>Poetic compositions of complex are a result of the photographs that are understood as a medium of discovery, remembering, imagining and thinking about time and places, those that are known and unknown.</p>
      <p><span className='bold'>Press</span><br />
        <a href="https://www.ignant.com/submissions/s3-by-vedran-kolac/" target="_blank">iGNANT</a><br />
        <a href="http://www.calvertjournal.com/photography/show/8659/modernist-architecture-croatia-split" target="_blank">The Calvert Journal</a><br />
        <a href="https://vizkultura.hr/s3/" target="_blank">VIZKULTURA</a><br />
        <a href="http://www.huckmagazine.com/art-and-culture/photography-2/travel-diary-split/" target="_blank">Huck Magazine</a><br />
        <a href="http://designed.rs/blenda/vedran_kolac" target="_blank">Designed</a><br />
      </p>
    </div>
  )
}

export const S3 = () => {
  const { id } = useParams();
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="project-detail-page">
      <h1>• S3 •</h1>
      <nav>
        <ul>
          <li><NavLink to="/photography/s3/gallery">Gallery</NavLink><span>,&nbsp;</span></li>
          <li><NavLink to="/photography/s3/description">Description</NavLink></li>
        </ul>
      </nav>
      {(id === 'gallery' ? <Gallery /> : <Description />)}
    </div>
  )
}