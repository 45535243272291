import { NavLink, useParams } from 'react-router-dom';
import { useEffect } from 'react'

const Gallery = () => {
  return(
    <div className='gallery-holder'>
      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/zn/1_00400024.jpg" alt="" /></div>
      </div>
      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/zn/2_00400012.jpg" alt="" /></div>
      </div>
      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/zn/3_00390029.jpg" alt="" /></div>
      </div>
      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/zn/4_00390027.jpg" alt="" /></div>
      </div>
      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/zn/5_00390037.jpg" alt="" /></div>
      </div>
      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/zn/6_00400026.jpg" alt="" /></div>
      </div>
      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/zn/7_00400033.jpg" alt="" /></div>
      </div>
      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/zn/8_00410024.jpg" alt="" /></div>
      </div>
      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/zn/9_00410026.jpg" alt="" /></div>
      </div>
      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/zn/10_00390026.jpg" alt="" /></div>
      </div>
      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/zn/11_00410027.jpg" alt="" /></div>
      </div>
      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/zn/12_00410029.jpg" alt="" /></div>
      </div>
    </div>
  )
}
const Description = () => {
  return(
    <div className="article-holder">
      <p>Zagreb North walk.</p>
    </div>
  )
}

export const ZN = () => {
  const { id } = useParams();
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="project-detail-page">
      <h1>• ZN •</h1>
      <nav>
        <ul>
          <li><NavLink to="/photography/zn/gallery">Gallery</NavLink><span>,&nbsp;</span></li>
          <li><NavLink to="/photography/zn/description">Description</NavLink></li>
        </ul>
      </nav>
      {(id === 'gallery' ? <Gallery /> : <Description />)}
    </div>
  )
}