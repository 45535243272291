import { NavLink, useParams } from 'react-router-dom';
import { useEffect } from 'react'

const Gallery = () => {
  return(
    <div className='gallery-holder'>
      <div className='pair-gallery'>
        <div className='img-holder'><img src="../../assets/photography/mk/05180006.jpg" alt="" /></div>
        <div className='img-holder'><img src="../../assets/photography/mk/05160035.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><img src="../../assets/photography/mk/05180008.jpg" alt="" /></div>
        <div className='img-holder'><img src="../../assets/photography/mk/05180007.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><img src="../../assets/photography/mk/05180004.jpg" alt="" /></div>
        <div className='img-holder'><img src="../../assets/photography/mk/05180015.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><img src="../../assets/photography/mk/05180014.jpg" alt="" /></div>
        <div className='img-holder'><img src="../../assets/photography/mk/05180013.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><img src="../../assets/photography/mk/05160030.jpg" alt="" /></div>
        <div className='img-holder'><img src="../../assets/photography/mk/05160032.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><img src="../../assets/photography/mk/5_05160015.jpg" alt="" /></div>
        <div className='img-holder'><img src="../../assets/photography/mk/05160023.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><img src="../../assets/photography/mk/05150001.jpg" alt="" /></div>
        <div className='img-holder'><img src="../../assets/photography/mk/05150003.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><img src="../../assets/photography/mk/4_05160017.jpg" alt="" /></div>
        <div className='img-holder'><img src="../../assets/photography/mk/05150032.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><img src="../../assets/photography/mk/05150024.jpg" alt="" /></div>
        <div className='img-holder'><img src="../../assets/photography/mk/05150022.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><img src="../../assets/photography/mk/05150012.jpg" alt="" /></div>
        <div className='img-holder'><img src="../../assets/photography/mk/1_05150014.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><img src="../../assets/photography/mk/3_05150008.jpg" alt="" /></div>
        <div className='img-holder'><img src="../../assets/photography/mk/2_05150007.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><img src="../../assets/photography/mk/05150035.jpg" alt="" /></div>
        <div className='img-holder'><img src="../../assets/photography/mk/6_05150031.jpg" alt="" /></div>
      </div>
    </div>
  )
}
const Description = () => {
  return(
    <div className="article-holder">
      <p>Around Makarska and Hvar.</p>
    </div>
  )
}

export const MK = () => {
  const { id } = useParams();
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="project-detail-page">
      <h1>• MK •</h1>
      <nav>
        <ul>
          <li><NavLink to="/photography/mk/gallery">Gallery</NavLink><span>,&nbsp;</span></li>
          <li><NavLink to="/photography/mk/description">Description</NavLink></li>
        </ul>
      </nav>
      {(id === 'gallery' ? <Gallery /> : <Description />)}
    </div>
  )
}