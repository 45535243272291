import { Link } from 'react-router-dom';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import { Photo } from './Photo';

export const Commercial = () => {
  return (
    <div className="project-list-holder">
      <ResponsiveMasonry columnsCountBreakPoints={{0: 2, 640: 2, 1024: 3, 1440: 3, 1920: 4, 2560: 4, 3200: 5}}>
        <Masonry gutter='20px'>
          <div className="thumb" >
            <Link to="/commercial/digital-showroom/gallery">
              <div className='img-container'>
              <Photo src={'../assets/commercial/digital-showroom/digital-showroom-thumb.jpg'} />
              </div>
              <h1>• Digital Showroom •</h1>
            </Link>
          </div>
          <div className="thumb" >
            <Link to="/commercial/one-smart-web/gallery">
              <div className='img-container'>
              <Photo src={'../assets/commercial/one-smart-web/one-smart-web-thumb.jpg'} />
              </div>
              <h1>• One Smart Web •</h1>
            </Link>
          </div>
          <div className="thumb" >
            <Link to="/commercial/advantages/gallery">
              <div className='img-container'>
              <Photo src={'../assets/commercial/advantages/advantages-thumb.jpg'} />
              </div>
              <h1>• ADVANTAGES •</h1>
            </Link>
          </div>
          <div className="thumb" >
            <Link to="/commercial/one-web-vans/gallery">
              <div className='img-container'>
              <Photo src={'../assets/commercial/one-web-vans/one-web-vans-thumb.jpg'} />
              </div>
              <h1>• OneWeb Vans •</h1>
            </Link>
          </div>
          <div className="thumb" >
            <Link to="/commercial/goodyear/gallery">
              <div className='img-container'>
              <Photo src={'../assets/commercial/goodyear/goodyear-thumb-3.jpg'} />
              </div>
              <h1>• Good Year •</h1>
            </Link>
          </div>
          <div className="thumb" >
            <Link to="/commercial/peace-collective/gallery">
              <div className='img-container'>
              <Photo src={'../assets/commercial/peace-collective/peace-collective-thumb.jpg'} />
              </div>
              <h1>• Peace Collective •</h1>
            </Link>
          </div>
          {/* <div className="thumb" >
            <Link to="/commercial/sphera/gallery">
              <div className='img-container'>
              <Photo src={'../assets/commercial/sfera/sfera-thumb.jpg'} />
              </div>
              <h1>• sphera •</h1>
            </Link>
          </div>
          <div className="thumb" >
            <Link to="/commercial/disko404/gallery">
              <div className='img-container'>
              <Photo src={'../assets/commercial/disko404/disko404-thumb.jpg'} />
              </div>
              <h1>• disko404 •</h1>
            </Link>
          </div>
          <div className="thumb" >
            <Link to="/commercial/tendency/gallery">
              <div className='img-container'>
              <Photo src={'../assets/commercial/tendency/tendency-thumb-2.jpg'} />
              </div>
              <h1>• Tendency •</h1>
            </Link>
          </div> */}
        </Masonry>
      </ResponsiveMasonry>
    </div>
  )
}