import { Link } from 'react-router-dom';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

export const Photography = () => {
  return (
    <div className="project-list-holder">
      <ResponsiveMasonry columnsCountBreakPoints={{0: 2, 640: 3, 1024: 4, 1440: 5, 3200: 6}}>
        <Masonry gutter='20px'>
          <div className="thumb" >
            <Link to="/photography/rtk/gallery">
              <img src="../assets/photography/rtk/rtk.jpg" alt="" />
              <h1>• RTK •</h1>
            </Link>
          </div>
          <div className="thumb" >
            <Link to="/photography/mk/gallery">
              <img src="../assets/photography/mk/mk.jpg" alt="" />
              <h1>• MK •</h1>
            </Link>
          </div>
          <div className="thumb" >
            <Link to="/photography/mln/gallery">
              <img src="../assets/photography/mln/mln.jpg" alt="" />
              <h1>• MLN •</h1>
            </Link>
          </div>
          <div className="thumb" >
            <Link to="/photography/ldn/gallery">
              <img src="../assets/photography/ldn/ldn.jpg" alt="" />
              <h1>• LDN •</h1>
            </Link>
          </div>
          <div className="thumb" >
            <Link to="/photography/zn/gallery">
              <img src="../assets/photography/zn/zn.jpg" alt="" />
              <h1>• ZN •</h1>
            </Link>
          </div>
          <div className="thumb" >
            <Link to="/photography/zw/gallery">
              <img src="../assets/photography/zw/zw.jpg" alt="" />
              <h1>• ZW •</h1>
            </Link>
          </div>
          <div className="thumb" >
            <Link to="/photography/wz2/gallery">
              <img src="../assets/photography/wz2/wz2.jpg" alt="" />
              <h1>• WZ 2 •</h1>
            </Link>
          </div>
          <div className="thumb" >
            <Link to="/photography/wp/gallery">
              <img src="../assets/photography/wp/wp.jpg" alt="" />
              <h1>• WP •</h1>
            </Link>
          </div>
          <div className="thumb" >
            <Link to="/photography/wz1/gallery">
              <img src="../assets/photography/wz1/wz1.jpg" alt="" />
              <h1>• WZ 1 •</h1>
            </Link>
          </div>
          <div className="thumb" >
            <Link to="/photography/s3/gallery">
              <img src="../assets/photography/s3/s3.jpg" alt="" />
              <h1>• S3 •</h1>
            </Link>
          </div>
          <div className="thumb" >
            <Link to="/photography/d/gallery">
              <img src="../assets/photography/d/d.jpg" alt="" />
              <h1>• D •</h1>
            </Link>
          </div>
          <div className="thumb" >
            <Link to="/photography/k/gallery">
              <img src="../assets/photography/k/k.jpg" alt="" />
              <h1>• K •</h1>
            </Link>
          </div>
          <div className="thumb" >
            <Link to="/photography/bz/gallery">
              <img src="../assets/photography/bz/bz.jpg" alt="" />
              <h1>• BZ •</h1>
            </Link>
          </div>
          {/* <div className="thumb" >
            <Link to="/photography/p/gallery">
              <img src="../assets/photography/p/prt.jpg" alt="" />
              <h1>• P •</h1>
            </Link>
          </div> */}
          <div className="thumb" >
            <Link to="/photography/apc/gallery">
              <img src="../assets/photography/apc/apc.jpg" alt="" />
              <h1>• APC •</h1>
            </Link>
          </div>
        </Masonry>
      </ResponsiveMasonry>
    </div>
  )
}