import { NavLink, useParams } from 'react-router-dom';
import { useEffect } from 'react'

const Gallery = () => {
  return(
    <div className='gallery-holder'>
      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/k/2_91240004.jpg" alt="" /></div>
      </div>
      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/k/3_91240006.jpg" alt="" /></div>
      </div>
      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/k/1_91240002_2.jpg" alt="" /></div>
      </div>
      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/k/4_91240014.jpg" alt="" /></div>
      </div>
      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/k/5_91240015.jpg" alt="" /></div>
      </div>
      <div className='single-gallery'>
        <div className='img-holder'><img src="../../assets/photography/k/6_91240036.jpg" alt="" /></div>
      </div>
      <div className='single-gallery'>
      <div className='img-holder'><img src="../../assets/photography/k/8_91240029.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><img src="../../assets/photography/k/11_91290006.jpg" alt="" /></div>
        <div className='img-holder'><img src="../../assets/photography/k/12_91290008.jpg" alt="" /></div>
      </div>
    </div>
  )
}
const Description = () => {
  return(
    <div className="article-holder">
      <p>Trip to Krvavica sanitarium in Baško polje.</p>
    </div>
  )
}

export const K = () => {
  const { id } = useParams();
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="project-detail-page">
      <h1>• K •</h1>
      <nav>
        <ul>
          <li><NavLink to="/photography/k/gallery">Gallery</NavLink><span>,&nbsp;</span></li>
          <li><NavLink to="/photography/k/description">Description</NavLink></li>
        </ul>
      </nav>
      {(id === 'gallery' ? <Gallery /> : <Description />)}
    </div>
  )
}