import { NavLink, useParams } from 'react-router-dom';
import { useEffect } from 'react'

const Gallery = () => {
  return(
    <div className='gallery-holder'>
      <div className='pair-gallery'>
        <div className='img-holder'><img src="../../assets/photography/mln/02470011.jpg" alt="" /></div>
        <div className='img-holder'><img src="../../assets/photography/mln/02470029.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><img src="../../assets/photography/mln/02470028.jpg" alt="" /></div>
        <div className='img-holder'><img src="../../assets/photography/mln/02470022.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><img src="../../assets/photography/mln/02470020.jpg" alt="" /></div>
        <div className='img-holder'><img src="../../assets/photography/mln/02470013.jpg" alt="" /></div>
      </div>
    </div>
  )
}
const Description = () => {
  return(
    <div className="article-holder">
      <p>Trip to Milano.</p>
    </div>
  )
}

export const MLN = () => {
  const { id } = useParams();
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="project-detail-page">
      <h1>• MLN •</h1>
      <nav>
        <ul>
          <li><NavLink to="/photography/mln/gallery">Gallery</NavLink><span>,&nbsp;</span></li>
          <li><NavLink to="/photography/mln/description">Description</NavLink></li>
        </ul>
      </nav>
      {(id === 'gallery' ? <Gallery /> : <Description />)}
    </div>
  )
}