import { NavLink, useParams } from 'react-router-dom';
import { useEffect } from 'react'
import { Photo } from '../Photo';

const Gallery = () => {
  return(
    <div className='gallery-holder'>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/crash/1.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/crash/2.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/crash/3.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/crash/4.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/crash/5.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/crash/6.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/crash/7.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/crash/8.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/crash/9.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/crash/10.jpg" alt="" /></div>
      </div>
    </div>
  )
}

const Description = () => {
  return(
    <div className="article-holder">
      <p>The Crash is an exploration of high-velocity collisions in zero gravity.</p>
      <p>It’s made with three.js and Rapier — a fast game/physics engine made with Rust programming language. All textures, normal and light maps are created dynamically using Canvas. This work is best viewed at 4k and 120Hz or better display.</p>
      <p>Published as NFT series on <a href="https://www.fxhash.xyz/generative/slug/crash">fxhash</a><br />
      Also available on <a href="https://vedrankolac.github.io/gravity/">GitHub</a></p>
      <p><i>Software</i><br />
        Javascript, threejs and Rapier
      </p>
    </div>
    
  )
}

export const CRASH = () => {
  const { id } = useParams();
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="project-detail-page">
      <h1>• CRASH •</h1>
      <nav>
        <ul>
          <li><NavLink to="/private/crash/gallery">Gallery</NavLink><span>,&nbsp;</span></li>
          <li><NavLink to="/private/crash/description">Description</NavLink></li>
        </ul>
      </nav>
      {(id === 'gallery' ? <Gallery /> : <Description />)}
    </div>
  )
}