import { NavLink, useParams } from 'react-router-dom';
import { useEffect } from 'react'
import { Photo } from '../Photo';

const Gallery = () => {
  return(
    <div className='gallery-holder'>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/schallfeld-ens/IMG_3686_D7C8A6BD.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/schallfeld-ens/IMG_3695_0CF51FEF.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/schallfeld-ens/39692340175_3b659edf8b_o_1920px_2.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/schallfeld-ens/40586975141_d088cdea1a_o.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/schallfeld-ens/40586984801_61bd235696_o.jpg" alt="" /></div>
      </div>
      <div className='inline-description'>
        <p>Screen</p>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/schallfeld-ens/r_1.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/schallfeld-ens/r_3.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/schallfeld-ens/r_4.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/schallfeld-ens/r_5.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/schallfeld-ens/r_6.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/schallfeld-ens/r_7.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/schallfeld-ens/r_8.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/schallfeld-ens/r_9.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/schallfeld-ens/r_10.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/schallfeld-ens/r_11.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/schallfeld-ens/r_13.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/schallfeld-ens/r_14.jpg" alt="" /></div>
      </div>
    </div>
  )
}

const Description = () => {
  return(
    <div className="article-holder">
      <p>"TodaysArts take over of Graz’s Orpheum starts with a series of four A/V live performances. Australian/Iceland-based Ben Frost & German MFO, Austrian guitarist and producer Fennesz & Swedish media artist Lillevan, international Graz-based Schallfeld Ensemble & Croatian visual artist Vedran Kolac and Austrian violinist Mia Zabelka & designer and visual artist Tina Frank." <br />— <i>Elevate Festival</i></p>
      <p>Event was made together with <a href="https://elevate.at/" target="_blank">Elevate</a> festival and <a href="https://todaysart.org/" target="_blank">TodaysArt</a> as a part of <a href="https://weare-europe.eu/" target="_blank">We are Europe</a> showcase.</p>
      <p>Performance visuals were made with a custom built software in a close collaboration with Schallfeld Ensemble.</p>
      <p><i>Software</i><br />
        vvvv
      </p>
      <p><i>Image credits</i><br />lupi spuma</p>
    </div>
    
  )
}

export const SCHALLFELD = () => {
  const { id } = useParams();
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="project-detail-page">
      <h1>• TodaysArt.SE •</h1>
      <nav>
        <ul>
          <li><NavLink to="/private/schallfeld-ens/gallery">Gallery</NavLink><span>,&nbsp;</span></li>
          <li><NavLink to="/private/schallfeld-ens/description">Description</NavLink></li>
        </ul>
      </nav>
      {(id === 'gallery' ? <Gallery /> : <Description />)}
    </div>
  )
}