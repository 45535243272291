import { useState, useEffect } from 'react'
import { Routes, Route, NavLink, Navigate, useLocation } from 'react-router-dom';
import GoogleFontLoader from 'react-google-font-loader';

import { Projects } from './pages/Projects';
import { Commercial } from './pages/Commercial';
import { Time } from './pages/Time';
import { Photography } from './pages/Photography';
import { About } from './pages/About';
import { NotFound } from './pages/NotFound';

import { RTK } from './pages/photography/RTK';
import { MK } from './pages/photography/MK';
import { MLN } from './pages/photography/MLN';
import { LDN } from './pages/photography/LDN';
import { ZN } from './pages/photography/ZN';
import { ZW } from './pages/photography/ZW';
import { WZ2 } from './pages/photography/WZ2';
import { WP } from './pages/photography/WP';
import { WZ1 } from './pages/photography/WZ1';
import { S3 } from './pages/photography/S3';
import { D } from './pages/photography/D';
import { K } from './pages/photography/K';
import { BZ } from './pages/photography/BZ';
import { P } from './pages/photography/P';
import { APC } from './pages/photography/APC';

import { CALL } from './pages/private/CALL';
import { ENDLESS } from './pages/private/ENDLESS';
import { MOEII } from './pages/private/MOEII';
import { MOEI } from './pages/private/MOEI';
import { DTVOID } from './pages/private/DTVOID';
import { SN } from './pages/private/SN';
import { VREXP } from './pages/private/VREXP';
import { ARTGERECHT } from './pages/private/ARTGERECHT';
import { SCHALLFELD } from './pages/private/SCHALLFELD';
import { MNSTR } from './pages/private/MNSTR';
import { AUTHONOMY } from './pages/private/AUTHONOMY';
import { CRASH } from './pages/private/CRASH';
import { FLOATINGCITIES } from './pages/private/FLOATINGCITIES';

import { DIGITALSHOWROOM } from './pages/commercial/DIGITALSHOWROOM';
import { MBSMART } from './pages/commercial/MBSMART';
import { MBVANS } from './pages/commercial/MBVANS';
import { ADVANTAGES } from './pages/commercial/ADVANTAGES';
import { PEACECOLLECTIVE } from './pages/commercial/PEACECOLLECTIVE';
import { SPHERA } from './pages/commercial/SPHERA';
import { GOODYEAR } from './pages/commercial/GOODYEAR';
import { DISKO404 } from './pages/commercial/DISKO404';

import './App.scss';

const App = () => {
  return (
    <>
      <GoogleFontLoader
        fonts={[
          {
            font: 'Roboto',
            weights: [100, '100i', 300, '300i', 500],
          },
        ]}
        subsets={['latin-ext']}
      />
      <div className='app'>
        <MainMenu />
        <Routes>
          <Route path="/time" element={<Time />} />
          <Route path="/private">
            <Route index element={<Projects />} />
            <Route path="c-all/:id" element={<CALL />} />
            <Route path="endless/:id" element={<ENDLESS />} />
            <Route path="moe-2/:id" element={<MOEII />} />
            <Route path="moe-1/:id" element={<MOEI />} />
            <Route path="disturbing-the-void/:id" element={<DTVOID />} />
            <Route path="space-is-only-noise/:id" element={<SN />} />
            <Route path="vr-exp/:id" element={<VREXP />} />
            <Route path="artgerecht/:id" element={<ARTGERECHT />} />
            <Route path="schallfeld-ens/:id" element={<SCHALLFELD />} />
            <Route path="monsters/:id" element={<MNSTR />} />
            <Route path="authonomy/:id" element={<AUTHONOMY />} />
            <Route path="crash/:id" element={<CRASH />} />
            <Route path="floating-cities/:id" element={<FLOATINGCITIES />} />
          </Route>

          <Route path="/commercial">
            <Route index element={<Commercial />} />
            <Route path="digital-showroom/:id" element={<DIGITALSHOWROOM />} />
            <Route path="one-smart-web/:id" element={<MBSMART />} />
            <Route path="one-web-vans/:id" element={<MBVANS />} />
            <Route path="advantages/:id" element={<ADVANTAGES />} />
            <Route path="peace-collective/:id" element={<PEACECOLLECTIVE />} />
            <Route path="sphera/:id" element={<SPHERA />} />
            <Route path="goodyear/:id" element={<GOODYEAR />} />
            <Route path="disko404/:id" element={<DISKO404 />} />
          </Route>

          <Route path="/photography">
            <Route index element={<Photography />} />
            <Route path="rtk/:id" element={<RTK />} />
            <Route path="mk/:id" element={<MK />} />
            <Route path="mln/:id" element={<MLN />} />
            <Route path="ldn/:id" element={<LDN />} />
            <Route path="zn/:id" element={<ZN />} />
            <Route path="zw/:id" element={<ZW />} />
            <Route path="wz2/:id" element={<WZ2 />} />
            <Route path="wp/:id" element={<WP />} />
            <Route path="wz1/:id" element={<WZ1 />} />
            <Route path="s3/:id" element={<S3 />} />
            <Route path="d/:id" element={<D />} />
            <Route path="k/:id" element={<K />} />
            <Route path="bz/:id" element={<BZ />} />
            <Route path="p/:id" element={<P />} />
            <Route path="apc/:id" element={<APC />} />
          </Route>
          <Route path="/about" element={<About />} />
          <Route path="/" element={<Navigate to="/private" replace />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </>
  );
}

const MainMenu = () => {
  const [isCommercial, setisCommercial] = useState(null);
  let location = useLocation();

  useEffect(() => {
    const isc = window.location.pathname.split('/')[1] == 'commercial' ? true : false;
    setisCommercial(isc);
  }, [location])
  
  return(
    <nav>
      <ul>
        <li className="logo">VEDRAN KOLAC</li>
        {/* <li><span>,&nbsp;</span><NavLink to="/time">~Time</NavLink><span>,&nbsp;</span></li> */}
        {isCommercial ? (
          <li><span>,&nbsp;</span><NavLink to="/private">~Personal</NavLink><span>,&nbsp;</span></li>
        ) : (
          <li><span>,&nbsp;</span><NavLink to="/private">~Projects</NavLink><span>,&nbsp;</span></li>
        )}
        {isCommercial && <li><span></span><NavLink to="/commercial">Contract</NavLink><span>,&nbsp;</span></li> }
        {/* <li><NavLink to="/photography">*p</NavLink><span>,&nbsp;</span></li> */}
        <li><NavLink to="/about">About</NavLink></li>
        {/* <li><NavLink to="/twitter">Twitter</NavLink><span>,&nbsp;</span></li> */}
      </ul>
    </nav>
  )
}

export default App;
