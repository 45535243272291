import { Link } from 'react-router-dom';
import { useEffect } from 'react';

export const About = () => {

  useEffect(()  => {
    document.body.classList.add('bg-color');

    return () => {
        document.body.classList.remove('bg-color');
    };
  });

  return (
    <div className="about-content-holder">
      <div className="article-holder">
        <p>Hello, I am Vedran Kolac</p>
        <p>I am a software engineer and creative technologist based in Berlin.</p>
        <p>My areas of interest lie in emergent systems, complexity, and related topics of computer science. I am fluent in several programming languages and design, sketching, drawing, and animation tools, particularly those that work with code.</p>
        <p>I have worked with and consulted many digital production companies and agencies. I was the founder and director of a digital production studio Tendency and a small generative art studio Onoxo. Together with Simon Morasi Pipercic, I am one of two authors of MOE interactive installations. I was a board member of the Artists Association ULUPUH and a member of the Multimedia Institute in Zagreb.</p>
        <p>Some of my work was featured in Designboom, Frame Mag, Domus, iGNANT, Calvert Journal, Digicult, and various local media. I have exhibited and performed at events like TodaysArt+Elevate (Graz), Electron (Geneve), ClubToClub (Torino), Mixer (Belgrade), and many, many more. Some of the work that I worked on was awarded the ADC award.</p>
        {/* <p>He is making photos since his early childhood and has made a few online published documentary series. <Link to="/photography/s3/gallery">S3</Link> would be the most prominent one.</p> */}
        <p>Occasionally, I do a workshop or lecture based on my current creative practice.</p>
        <p><i>Email: vedran.kolac@gmail.com</i><br />
        <i>Github: <a href="https://github.com/vedrankolac" target="blank">github.com/vedrankolac</a></i><br />
        </p>
        <p></p>
      </div>
    </div>
  )
}