import { NavLink, useParams } from 'react-router-dom';
import { useEffect } from 'react'
import { Photo } from '../Photo';

const Gallery = () => {
  return(
    <div className='gallery-holder'>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/space-is-only-noise/1.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/space-is-only-noise/2.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/space-is-only-noise/3.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/space-is-only-noise/4.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/space-is-only-noise/6.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/space-is-only-noise/5.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/space-is-only-noise/7.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/space-is-only-noise/8.jpg" alt="" /></div> 
        <div className='img-holder'><Photo src="../../assets/private/space-is-only-noise/10.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/space-is-only-noise/9.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/space-is-only-noise/11.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/space-is-only-noise/12.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/space-is-only-noise/13.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/space-is-only-noise/14.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/space-is-only-noise/15.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/space-is-only-noise/16.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/space-is-only-noise/17.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/space-is-only-noise/18.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/space-is-only-noise/19.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/space-is-only-noise/20.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/space-is-only-noise/21.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/space-is-only-noise/22.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/space-is-only-noise/23.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/space-is-only-noise/24.jpg" alt="" /></div>
      </div>
    </div>
  )
}

const Description = () => {
  return(
    <div className="article-holder">
      <p>Series of still images from custom-made software for generative arts. I used this software for performances at various electronic music events, such as Elevate (AT), ClubToClub(IT), Dimensions (HR), LeZoo (CH), and many more.</p>
      <p><i>Software</i><br />
        vvvv
      </p>
    </div>
    
  )
}

export const SN = () => {
  const { id } = useParams();
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="project-detail-page">
      <h1>• S/N •</h1>
      <nav>
        <ul>
          <li><NavLink to="/private/space-is-only-noise/gallery">Gallery</NavLink><span>,&nbsp;</span></li>
          <li><NavLink to="/private/space-is-only-noise/description">Description</NavLink></li>
        </ul>
      </nav>
      {(id === 'gallery' ? <Gallery /> : <Description />)}
    </div>
  )
}