import { NavLink, useParams } from 'react-router-dom';
import { useEffect } from 'react'
import { Photo } from '../Photo';

const Gallery = () => {
  return(
    <div className='gallery-holder'>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/vr-exp/1.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/vr-exp/4.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/vr-exp/2.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/vr-exp/3.jpg" alt="" /></div>
      </div>
    </div>
  )
}

const Description = () => {
  return(
    <div className="article-holder">
      <p>Explorations with spatial sound in VR environment</p>
      <p><i>Software</i><br />
        Javascript, threejs and tonejs
      </p>
    </div>
    
  )
}

export const VREXP = () => {
  const { id } = useParams();
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="project-detail-page">
      <h1>• VR EXP •</h1>
      <nav>
        <ul>
          <li><NavLink to="/private/vr-exp/gallery">Gallery</NavLink><span>,&nbsp;</span></li>
          <li><NavLink to="/private/vr-exp/description">Description</NavLink></li>
        </ul>
      </nav>
      {(id === 'gallery' ? <Gallery /> : <Description />)}
    </div>
  )
}